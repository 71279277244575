<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-2 mt-6">
      <div class="input-wrap">
        <h5 class="mb-2">Historial de caja</h5>
      </div>
      <div class="action-btn-wrap"></div>
    </div>
    <v-card color="secondary" class="pa-3">
      <emb-data-table :resource="resource" :columns="columns">
        <tr slot="heading">
          <th class="text-right">N°</th>
          <th class="text-center">CAJA</th>
          <th class="text-center">RESPONSABLE</th>
          <th class="text-center">FECHA APERTURA</th>
          <th class="text-center">HORA APERTURA</th>
          <th class="text-center">FECHA CIERRE</th>
          <th class="text-center">HORA CIERRE</th>
          <th class="text-right">SALDO INICIAL</th>
          <th class="text-right">TOTAL INGRESOS</th>
          <th class="text-right">TOTAL EGRESOS</th>
          <th class="text-right">SALDO FINAL</th>
          <th class="text-right">ACCIONES</th>
        </tr>

        <tr></tr>
        <tr slot-scope="{ index, row }">
          <td class="text-right">{{ index }}</td>
          <td class="text-center">{{ row.cash }}</td>
          <td class="text-center">{{ row.user }}</td>
          <td class="text-center">{{ row.date_opening }}</td>
          <td class="text-center">{{ row.time_opening }}</td>
          <td class="text-center">{{ row.date_closed }}</td>
          <td class="text-center">{{ row.time_closed }}</td>
          <td class="text-right">{{ row.beginning_balance }}</td>
          <td class="text-right">{{ row.income }}</td>
          <td class="text-right">{{ row.expense }}</td>
          <td class="text-right">
            <strong class="text-success font-bold">
              {{ row.final_balance }}
            </strong>
          </td>
          <td class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="accent"
                  class="ma-1"
                  fab
                  x-small
                  dark
                  @click="showDetails(row.id)"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Ver detalles</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="accent"
                  class="ma-1"
                  fab
                  x-small
                  dark
                  @click="getData(row.id)"
                  v-on="on"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir</span>
            </v-tooltip>
          </td>
        </tr>
      </emb-data-table>
    </v-card>
    <show-details
      :showDialog.sync="showDialog"
      :recordId="recordId"
    ></show-details>
  </div>
</template>

<script>

import showDetails from "./Details";
export default {
  components: {
    showDetails,
  },
  data() {
    return {
      recordId: null,
      showDialog: false,
      resource: "openings-history",
      columns: {
        date_opening: "Fecha de apertura",
      },
      form: {}
    };
  },
  methods: {
    showDetails(recordId) {
      this.recordId = recordId;
      this.showDialog = true;
    },
    getData(recordID) {
      this.$http
          .get(`/openings/final-balance/${recordID}`)
          .then((resp) => {
            
            this.form = resp.data.data;

            this.clickPrint();
          });
    },
    clickPrint() {
      let data = JSON.stringify(this.form);
      // let pc_ip = data.config.pc_ip;

      let url = `http://print-api.test/print/final-balance`;
      // let url = `https://${pc_ip}/print-api/print/final-balance`;

      fetch(url, { method: 'POST', body: data })
        .then((dataWrapped) => dataWrapped.json())
        .then((data) => {
          if (data.success) {
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    }
  },
};
</script>
